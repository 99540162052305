<template>
  <div>
    <b-row>
      <b-col md="12" class="mb-2">
        <export-button
          :filter="exportFilter"
          url="/audit/exportAudits"
          :disabled="loadingData"
          file-title="Audit"
        />
      </b-col>
    </b-row>
    <b-card>
      <!-- FILTER START -->
      <b-row>
        <b-col md="3">
          <b-form-group label="Date from" label-for="datefrom" rules="required">
            <template #label>{{ $t("labels.datefrom") }}</template>
            <flat-pickr
              v-model="filterSelect.dateFrom"
              @input="inputSelect"
              class="form-control"
              :config="{
                          altInput: true,
                          altFormat: 'd-m-Y h:i K',
                          enableTime: true,
                          wrap : true,
                          plugins: [
                             new yearDropdownPlugin({
                               yearStart: 100,
                               yearEnd: 0
                            })
                           ],
                         }"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Date To" label-for="dateto" rules="required">
            <template #label>{{ $t("labels.dateto") }}</template>
            <flat-pickr
              v-model="filterSelect.dateAt"
              @input="inputSelect"
              class="form-control"
              :config="{
                          altInput: true,
                          altFormat: 'd-m-Y h:i K',
                          enableTime: true,
                          wrap : true,
                          plugins: [
                             new yearDropdownPlugin({
                               yearStart: 100,
                               yearEnd: 0
                            })
                           ],
                        }"
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group label="platform" label-for="platform" rules="required">
            <template #label>{{ $t("labels.platform") }}</template>
            <b-input
              v-model="filterSelect.platform"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              @input="inputSelect"
              autocomplete="off"
            >
            </b-input>
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group label-for="Modules">
            <template #label>{{ $t("labels.module") }}</template>
            <v-select
              @input="inputSelect"
              v-model="filterSelect.module"
              :options="modules"
              :filterable="false"
              :clearable="true"
              :reduce="module => module.name"
              :disabled="loadingActionsAndModulesEnum"
            >
              <template slot="option" slot-scope="option">
                {{ $t(`audit_md.modules.${option.name.toLowerCase()}`) }}
              </template>
              <template slot="selected-option" slot-scope="option">
                {{ $t(`audit_md.modules.${option.name.toLowerCase()}`) }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group label="typeUser" label-for="Actions">
            <template #label>{{ $t("labels.actions") }}</template>
            <v-select
              @input="inputSelect"
              v-model="filterSelect.action"
              :options="actions"
              :filterable="true"
              :clearable="true"
              :reduce="action => action.name"
              :disabled="loadingActionsAndModulesEnum"
            >
              <template slot="option" slot-scope="option">
                {{ $t(`audit_md.actions.${option.name.toLowerCase()}`) }}
              </template>
              <template slot="selected-option" slot-scope="option">
                {{ $t(`audit_md.actions.${option.name.toLowerCase()}`) }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group label="typeUser" label-for="typeUser">
            <template #label>{{ $t("labels.type_user")}}</template>
            <v-select
              @input="inputSelect"
              v-model="filterSelect.typeUser"
              :options="typeUsersOptios"
              :reduce="(status) => status.value"
              :filterable="false"
              :clearable="true"
              label="key"
            >
            </v-select>
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group label="userName" label-for="username">
            <template #label>{{ $t("labels.user")}}</template>
            <v-select
              @option:selected="selectPlayer"
              v-model="userNameValue"
              :options="userList"
              @search="onSearch"
              :filterable="false"
              :clearable="true"
              label="userName"
            >
              <template slot="no-options">
                {{ $t("type_a_letter_to_start_the_search") }}
              </template>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  {{ option.userName }}
                </div>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group label="userName" label-for="username">
            <template #label>{{ $t("target_user")}}</template>
            <v-select
              @option:selected="selectTargetUser"
              v-model="targetUserNameValue"
              :options="userList"
              @search="onSearch"
              :filterable="false"
              :clearable="true"
              label="userName"
            >
              <template slot="no-options">
                {{ $t("type_a_letter_to_start_the_search") }}
              </template>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  {{ option.userName }}
                </div>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- FILTER END -->
    </b-card>

    <b-card>
      <b-overlay :show="loadingData" variant="transparent" no-wrap />

      <!-- table -->
      <vue-good-table
        mode="remote"
        :columns="columns"
        :rows="rows"
        :totalRows="totalRows"
        :pagination-options="{ enabled: true }"
        :sort-options="{ enabled: false }"
      >
        <template slot="table-row" slot-scope="props">
          <div v-if="props.column.field === 'appliedUser'" class="text-nowrap">
            <div class="text-center">
              {{ props.row.appliedUser.userName || '-' }}
            </div>
          </div>
          <div
            v-else-if="props.column.field == 'createdAt'"
            class="text-nowrap"
          >
            <div>{{ props.row.createdAt | formatDateTimeHumane }}</div>
          </div>
          <div v-else-if="props.column.field == 'headers'" class="content-list">
            <b-button size="sm" @click="handleContentList(props.row._id)">
              <feather-icon icon="MoreVerticalIcon" size="18" />
            </b-button>

            <!-- show modal v-b-modal -->
            <div class="content-list-items" :class="{active: contentListId == props.row._id}">
              <b-button
                v-ripple.400="'rgba(0, 0, 0, 0.15)'"
                @click="openModal('headers', props.row._id, 'headers')"
                size="sm"
                variant="outline-primary"
              >
                {{ $t('headers') }}
              </b-button>

              <b-button
                v-ripple.400="'rgba(0, 0, 0, 0.15)'"
                @click="openModal('request', props.row._id, 'request')"
                size="sm"
                variant="outline-warning"
              >
                {{ $t('request') }}
              </b-button>

              <b-button
                v-ripple.400="'rgba(0, 0, 0, 0.15)'"
                @click="openModal('response', props.row._id, 'response')"
                size="sm"
                variant="outline-info"
              >
                {{ $t('response') }}
              </b-button>

              <b-button
                v-ripple.400="'rgba(0, 0, 0, 0.15)'"
                @click="openModal('previousValues', props.row._id, 'previous_values')"
                size="sm"
                variant="outline-success"
              >
                {{ $t('previous_values') }}
              </b-button>

              <b-button
                v-ripple.400="'rgba(0, 0, 0, 0.15)'"
                @click="openModal('currentValues', props.row._id, 'current_values')"
                size="sm"
                variant="outline-danger"
              >
                {{ $t('current_values') }}
              </b-button>
            </div>
          </div>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <!-- page length -->
            <div class="d-flex align-items-center mb-0 mt-1">
              <!--<span class="text-nowrap"> Showing 1 to </span>-->
              <b-form-select
                v-model="serverParams.perPage"
                :options="pages"
                class="mx-1"
                @input="onPerPageChange"
              />
              <!--<span class="text-nowrap"> of {{ props.total }} entries </span>-->
            </div>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="serverParams.page"
                :total-rows="props.total"
                :per-page="serverParams.perPage"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="onPageChange"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>

    <!-- Modal -->
    <b-modal
      size="xl"
      v-model="modal.show"
      :title="$t(modal.title)"
      cancel-variant="outline-secondary"
      :cancel-title="$t('buttons.cancel')"
      :ok-title="$t('buttons.accept')"
      :hide-header-close="modal.loading"
      :hide-footer="modal.loading"
      scrollable
    >
      <div
        v-if="modal.loading"
        class="d-flex align-items-center justify-content-center"
        style="height: 200px"
      >
        <b-spinner type="grow" small></b-spinner>
      </div>
      <prism v-else language="javascript">
        {{modal.data}}
      </prism>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { formatDateTimeHumane } from '@core/utils/filter'
import moment from "moment";
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'
import { translatableText } from '@core/utils/utils'
import { axiosErrorHandle } from '@core/utils/errorHandler'
import axios from "@/libs/axios";
import yearDropdownPlugin from "@/helpers/year_flatpickr_plugin";
import ExportButton from "@/views/pages/export/ExportButton.vue";

export default {
  components: {
    ExportButton,
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    VBModal,
    BCardText,
    VBToggle,
    vSelect,
    VueGoodTable,
    flatPickr,
    Prism,
    ToastificationContent,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      contentListId: null,
      userNameValue : '',
      targetUserNameValue: '',
      dates: "",
      loadingData: false,
      pages: [10, 20, 40, 60],
      modules : [],
      actions : [],
      loadingActionsAndModulesEnum : false,
      timeout: null,
      rows: [],
      serverParams: {
        page: 1,
        perPage: 10,
      },
      totalRows: 0,
      customerOptions: [],
      productsOptions: [],
      currencyssOptions: [],
      platformOptions: [
        { label: "Web", value: "web" },
        { label: "Admin", value: "admin" },
        { label: "Gateway", value: "gateway" },
      ],
      filterSelect: {
        dateFrom: moment().subtract(1, "month").format("YYYY-MM-DD 11:59"),
        dateAt: moment().format("YYYY-MM-DD 23:59"),
        platform: "",
        id: "",
        targetUserId: '',
        module : '',
        action : ''
      },
      userList: [],
      modal : {
        show : false,
        loading : false,
        data : '',
        title : ''
      }
    };
  },
  computed: {
    typeUsersOptios(){
      return [
        { key: this.$t('tabs.player'), value: "Admin" },
        { key: this.$t('tabs.agent'), value: "Agent" },
        { key: this.$t('tabs.player'), value: "Player" }
      ]
    },
    
    columns(){
      return [
          { label: this.$t("user"), field: "userId.userName" },
          { label: this.$t("target_user"), field: "appliedUser.userName" },
          { label: this.$t("platform"), field: "platform" },
          { label: this.$t("action"), field: "action" },
          { label: this.$t("ip"), field: "headers.cf-connecting-ip" },
          { label: this.$t("country"), field: "headers.cf-ipcountry" },
          { label: this.$t("session_id"), field: "sessionId" },
          { label: this.$t("content"), field: "headers" },
          { label: this.$t("method"), field: "method" },
          { label: this.$t("route"), field: "route" },
          { label: this.$t("params"), field: "params" },
          { label: this.$t("created_at"), field: "createdAt" },
        ]
    },
    exportFilter(){
      return {
        module : this.filterSelect.module,
        action : this.filterSelect.action,
        id : this.filterSelect.id,
        userId : this.filterSelect.targetUserId || null,
        platform : this.filterSelect?.platform,
        typeUser : this.filterSelect?.typeUser,
        dateAt : new Date(this.filterSelect.dateAt).toISOString(),
        dateFrom : new Date(this.filterSelect.dateFrom).toISOString(),
      }
    }
  },
  watch: {
    userNameValue(value) {
      if (value === null) {
        this.filterSelect.id = "";
        this.getAudit();
      }
    },
    targetUserNameValue(value) {
      if (value === null) {
        this.filterSelect.targetUserId = "";
        this.getAudit();
      }
    },
  },
  // add filters
  filters: {
    formatDateTimeHumane,
    pretty: function(value) {
      return value
    }
  },

  methods: {
    handleContentList(id){
      this.contentListId = this.contentListId === id ? null : id
    },
    yearDropdownPlugin,
    async getAudit(resetPagination = true ) {
      const filterSelectCopy = JSON.parse(JSON.stringify(this.filterSelect))
      try {
        if (resetPagination)
          this.serverParams.page = 1

        if(filterSelectCopy.platform == null){
          filterSelectCopy.platform = ""
        }

        if(filterSelectCopy.typeUser == null){
          delete filterSelectCopy.typeUser
        }
        if(filterSelectCopy.id == null || filterSelectCopy.id === ""){
          delete filterSelectCopy.id
        }
        if (filterSelectCopy.hasOwnProperty('targetUserId')){
          filterSelectCopy.userId = filterSelectCopy.targetUserId
          delete filterSelectCopy.targetUserId
          if(filterSelectCopy.userId == null || filterSelectCopy.userId === ""){
            delete filterSelectCopy.userId
          }
        }
        console.log(filterSelectCopy)
        const payload = {
          ...filterSelectCopy,
          dateAt : new Date(this.filterSelect.dateAt).toISOString(),
          dateFrom : new Date(this.filterSelect.dateFrom).toISOString(),
          "paginate": {
            ...this.serverParams,
            "sortDesc": true
          }
        }
        this.loadingData = true;
        const res = await this.$store.dispatch("moduleAudit/index", payload);
        this.rows = res.data.audits;
        this.totalRows = res.data.total;
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text : await translatableText({text: axiosErrorHandle(error)}),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loadingData = false;
      }
    },

    async getActionsAndModulesEnum(){
      try {
        this.loadingActionsAndModulesEnum = true
        const { data } = await this.$store.dispatch("moduleAudit/getActionAndModuleEnum");
        this.actions = data['action'].sort((a, b) => (a.name > b.name) ? 1 : -1);
        this.modules= data['module']
      }catch (e){
       console.log(e)
      } finally {
        this.loadingActionsAndModulesEnum = false
      }

    },
    onPageChange(page) {
      this.serverParams.page = page;
      this.getAudit(false);
    },
    onPerPageChange(perPage) {
      this.serverParams.page = 1
      this.serverParams.perPage = perPage;
      this.getAudit(false);
    },
    inputSelect(val) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.getAudit();
      }, 800);
    },
    selectPlayer({ _id }) {
      this.filterSelect.id = _id;
      this.getAudit();
    },
    selectTargetUser({_id}){
      this.filterSelect.targetUserId = _id
      this.getAudit()
    },
    async getCustomer() {
      const res = await this.$store.dispatch("reports/getCustomer");
      this.customerOptions = res.data;
    },
    async getProducts() {
      const res = await this.$store.dispatch("reports/getProducts");
      this.productsOptions = res.data;
    },
    async getCurrencys() {
      const res = await this.$store.dispatch("reports/getCurrencys");
      this.currencyssOptions = res.data.currencys;
    },
    search(loading, queryParams, vm) {
      loading(true);
      //console.log(this.userName.trim());.
      this.$store.dispatch("user/fetchUsersByName", queryParams).then((response) => {
        this.userList = response.data;
        loading(false);
      });
    },
    onSearch(username, loading) {
      if (username.length) {
        loading(true);

        const whitelabelId = this.$store.state.whitelabelCurrencyNabvar.whitelabel._id;
        const queryParams = { username: username.trim(), whitelabel: whitelabelId };

        if (this.timeOut) clearTimeout(this.timeOut);

        this.timeOut = setTimeout(() => {
          this.search(loading, queryParams, this);
        }, 500);
      }
    },
    async openModal(modal, id, title){
      try {
        this.modal.show = true
        this.modal.loading = true
        this.modal.title = title
        const { data } = await axios.get(`/audit/${modal}/${id}`)
        this.modal.data = data
        this.modal.loading = false
      } catch (e){
        console.log(e)
        this.modal.show = false
      }
    }

  },
  created() {
    // const intl = Intl.DateTimeFormat();
    // console.log(intl)
    // const timezone = intl.resolvedOptions().timeZone;
    // console.log(timezone)
    this.getAudit();
    this.getActionsAndModulesEnum()
  },
};
</script>

<style lang="scss">
@import "src/assets/scss/mixin.scss";

.per-page-selector {
  width: 90px;
}

.content-list{
  position: relative;

  &-items{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px;
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translate(10px, -50%);
    background-color: #fff;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 5px;
    z-index: 1;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    pointer-events: none;
    
    @include r(lg){
      display: flex;
    }

    &::before{
      content: '';
      position: absolute;
      top: 50%;
      right: -5px;
      width: 10px;
      height: 10px;

      transform: translateY(-50%) rotate(45deg);
      border: 5px solid transparent;
      background-color: #fff;
    }

    &.active{
      opacity: 1;
      pointer-events: all;
      transform: translate(-10px, -50%);
    }

    .btn{
      min-width: max-content
    }
  }
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
